import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Link from '../components/Link'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
      textDecoration: 'none',
    },
    marginBottom: 15,
  },
  active: {
    background: 'rgba(0, 0, 0, 0.07)',
  }
}));

const OutlinedChips = props => {
  const { items } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {items.map(item => {
        console.log(item.active)
        return (
          <Link
            exact="true"
            to={item.slug? item.slug : "/tags/".concat(item.title.toLowerCase().replace(' ', '-').replace('ú', 'u')) + '/'}
            className={item.active? classes.active : ''}
          >
            <Chip label={item.title} clickable variant='outlined' />
          </Link>
        )
      })}
    </div>
  );
}

export default OutlinedChips
