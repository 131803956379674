import React from 'react'
import PropTypes from 'prop-types'
import Typed from 'react-typed'
import MediaQuery from 'react-responsive'

import Link from './Link'
import Image from './Image'
import Content from './Content'
import './PageHeader.css'

const PageHeader = ({
  title,
  subtitle,
  backgroundImage,
  large,
  className = '',
  cta = '',
  options = []
}) => {
  if (large) className += ' PageHeader-large'
  return (
    <div className={`PageHeader relative ${className}`}>
      {backgroundImage && (
        <Image
          background
          resolutions="large"
          src={backgroundImage}
          alt={title}
          size="cover"
        />
      )}
      <div className="container relative">
        <MediaQuery minDeviceWidth={750}>
          <h1 className="PageHeader--Title">
            {title}
            &nbsp;
            {options.length != 0 && (
              <Typed
                  strings={options}
                  typeSpeed={80}
              />
            )}
          </h1>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={750 - 1}>
          <h1 className="PageHeader--Title">
            {title}
            <br></br>
            {options.length != 0 && (
              <Typed
                  strings={options}
                  typeSpeed={80}
              />
            )}
          </h1>
        </MediaQuery>
        {subtitle && (
          <Content className="PageHeader--Subtitle" src={'<span>'.concat(subtitle, '</span>')} />
        )}
        {cta && (
          <center>
            <Link
              exact="true"
              to="/recipes/"
            >
              <div class="Button">
                {cta}
              </div>
            </Link>
          </center>
        )}
      </div>
    </div>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
