import React from 'react'
import InstagramFeed from './InstagramFeed'
import Divider from '@material-ui/core/Divider';
import './Footer.css'

export default () => (
  <div>
    <Divider variant="middle" />
    <footer className="footer">
      <div className="container taCenter">
        <span>
          © {new Date().getFullYear()} MegaDesk OÜ. Todos os direitos reservados.
        </span>
      </div>
    </footer>
  </div>
)
