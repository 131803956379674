import React from 'react'
import AdSense from 'react-adsense'
import MediaQuery from 'react-responsive'

import Image from './Image'
import OutlinedChips from '../components/OutlinedChips'
import Link from '../components/Link'

import './PostCard.css'

const InFeedAd = (key) => {
  return (
    <div>
      <center className="SinglePost--Ad">
        <p className="SinglePost--AdLabel">PUBLICIDADE</p>
        <MediaQuery minDeviceWidth={750}>
          <div data-premium=""  data-adunit="MEU_CREME_BRULEE_ARTIGOS_1" data-sizes-desktop="[[300,250]]" data-sizes-mobile="[[300,250]]"  >
          </div>
        </MediaQuery>
        <MediaQuery maxDeviceWidth={750 - 1}>
          <div data-premium=""  data-adunit="MEU_CREME_BRULEE_ANCHOR_IAB" data-sizes-desktop="[[728,90]]" data-sizes-mobile="[[320,100]]"  >
          </div>
        </MediaQuery>
      </center>
    </div>
  )
}

const PostCard = ({
  featuredImage,
  title,
  excerpt,
  slug,
  categories = [],
  tags = [],
  className = '',
  ad=false,
  ...props
}) => {

  var trimmed = excerpt.replace(/^(.{120}[^\s]*).*/, "$1").concat('...');

  if (ad) {
    return (
      <div className="PostCard--Content ad">
        <InFeedAd />
      </div>
    )
  } else {
    return (
      <Link to={slug} className={`PostCard ${className}`}>
        {featuredImage && (
          <div className="PostCard--Image relative">
            <Image background src={featuredImage} alt={title} />
          </div>
        )}
        <div className="PostCard--Content">
          {title && <h3 className="PostCard--Title">{title}</h3>}
          {tags.length > 0 &&
            <div className="PostCard--Category">
              <OutlinedChips items={tags.slice(0,3).map(tag => ({title: tag.tag}))}/>
            </div>
          }
          {trimmed && <div className="PostCard--Excerpt">{trimmed}</div>}
        </div>
      </Link>
    )
  }
}

export default PostCard
